<template>
  <div class="login">
    <h2 class="login-header">日本冷蔵倉庫協会 調査システム</h2>

    <form class="login-container">
        <p><input type="text" placeholder="Email address" v-model="userid"></p>
        <p><input type="password" placeholder="Password" v-model="password"></p>
<!--
        <p>
          <input type="checkbox" name="keeplogin" v-model="keeplogin" class="checkbox" />
          <label for="keeplogin" class="checkbox-label" >ログインしたままにする</label>
        </p>
 -->
        <p><input type="button" value="ログイン" :disabled="login_btn" v-on:click='login()'></p>
    </form>

        <p class="right mt-3"><a href="/reset_password">パスワードを忘れた方はこちら</a></p>

    <div class="message preline">
        {{ message }}
    </div>
  </div>
</template>


<script>
import Axios from 'axios'
// import token from "../model/setToken"
// import Util from '../Lib/Util'
import Debug from '../Lib/Debug'

export default {
  name: 'Login',

  data: () => ({
    userid : '',
    password : '',
    message : '',
    keeplogin : true,
    login_btn : true,

    cookies_userid: null,
    cookies_token: null,

    urlRedirect: "",
  }),

  methods: {
    async login(){
      // パラメータ redirect を取得
      this.urlRedirect = this.$route.query.redirect != undefined
                 ? this.$route.query.redirect : "/";
      if ((this.urlRedirect.length <= 0) || (this.urlRedirect == '/')) {
        this.urlRedirect = "/"
      }

      Debug.log(this.urlRedirect);

      let params = new URLSearchParams();
      params.append('email', this.userid);
      params.append('password', this.password);

      try {
        const res = await Axios.post('/api/login', params);
        Debug.log(res);

        Debug.log("login status");
        Debug.log(res.status);

        if (res.status == 200) {
          this.message = "ログインしました";
          Debug.log("Login Token:" + res.data.api_token);

          // Tokenを保存
          this.$store.commit('setToken', res.data.api_token);
          sessionStorage.setItem("token", res.data.api_token);

          // ログインステータスを保存
          this.$store.commit('setLoginStat', true);
          Debug.log("Login vuex stat.login:" + this.$store.getters.getLoginStat);
          this.$store.commit('setUserid', this.userid.trim());
          sessionStorage.setItem("userid", res.data.id);
          this.$store.commit('setUsername', res.data.name);
          this.$store.commit('setCompanyId', res.data.companyId);
          this.$store.commit('setMemberNo', res.data.memberNo);
          this.$store.commit('setAttribute', res.data.attribute);
          this.$store.commit('setAssociation', res.data.association);
          this.$store.commit('setFrozenAssociation', res.data.frozenAssociation);

          // マイページリスト
          this.$store.commit('setMypageList', res.data.memberid_list);
          Debug.log2("LOGIN_DATA:", res.data.memberid_list);

          // パラメータ urlRedirect遷移
          Debug.log("redirect:" + this.urlRedirect);
          this.$router.push(this.urlRedirect);

        } else {
          this.message = "メールアドレス、もしくは\r\nパスワードが正しくありません";
          Debug.log(this.message);

          // ログインステータスを保存
          this.$store.commit('setLoginStat', false);
          this.$store.commit('setUserid', '');
          this.$store.commit('setUsername', '');
          Debug.log(this.$store.getters.getLoginStat);

          // this.$router.push('/login')
        }
      } catch (error) {
          this.message = "メールアドレス、もしくは\r\nパスワードが正しくありません";
          console.error(this.message);
          console.error(error);

          // this.$router.push('/login')
      }
    },

  },

  created: async function() {
    if (this.$store.getters.getLoginStat) {
          this.$router.push('/')
    }
  },

  mounted : function() {

      this.cookies_userid = this.$cookies.get("userid");
      this.cookies_token = this.$cookies.get("token");

      // Cookie を表示
      Debug.log("Load Cookies");
      Debug.log("  userid:" + this.cookies_userid);
      Debug.log("  token:" + this.cookies_token);

      if(this.cookies_userid) {
        this.userid = this.cookies_userid;
        this.login();
      } else {
        this.login_btn = false;
      }

  },
}

</script>

<style scoped>
.login {
  width: 450px;
  margin: 30px auto;
  font-size: 20px;
}

/* Reset top and bottom margins from certain elements */
.login-header,
.login p {
  margin-top: 0;
  margin-bottom: 0;
}

/* The triangle form is achieved by a CSS hack */
.login-triangle {
  width: 0;
  margin-right: auto;
  margin-left: auto;
  border: 12px solid transparent;
  border-bottom-color: var(--v-primary-base);
}

.login-header {
  /* background: var(--v-primary-base); */
  background-color: #05a;
  padding: 20px;
  font-size: 1.4em;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.login-container {
  background: #ebebeb;
  padding: 12px;
}

/* Every row inside .login-container is defined with p tags */
.login p {
  padding: 12px;
}

.login input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  padding: 16px;
  outline: 0;
  font-family: inherit;
  font-size: 0.95em;
}

.login input[type="text"],
.login input[type="password"] {
  background: #fff;
  border-color: #bbb;
  color: #555;
}

/* Text fields' focus effect */
.login input[type="email"]:focus,
.login input[type="password"]:focus {
  border-color: #888;
}

.login input[type="button"] {
  font-size: 20px;
  color: #fff;
  /* background: var(--v-primary-base); */
  background-color: #05a;
}

.login input[type="submit"] {
  background: var(--v-primary-base);
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}

.login input[type="submit"]:hover {
  background: #17c;
}

/* Buttons' focus effect */
.login input[type="submit"]:focus {
  border-color: #05a;
}

.message {
  margin: 20px;
  text-align: center;
  font-weight: bold;
  color: red;
}

.checkbox {
  display: inline-block !important;
  width: auto !important;
  margin-left: 50px;
  margin-right: 10px;
}

.checkbox-label {
  color: #000;
}

</style>
