<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <div class="login">
      <h2 class="login-header">日本冷蔵倉庫協会 調査システム</h2>

      <!-- メールアドレス入力 -->
      <div v-if="mode=='email'" class="login-container">
        <div class="left ml-5 mt-3 mb-5">
          パスワード再設定のためのURLをお送りします<br>
          メールアドレスを入力して下さい
        </div>

        <p><v-text-field placeholder="Email address" 
              v-model="email"
              :rules="[required, validEmail]"
              class="input-email"
        ></v-text-field></p>
        <v-text-field v-show="false"></v-text-field>

        <p><input type="button" value="再設定メールを送信" v-on:click='reset_password()'></p>
      </div>

      <!-- 新パスワード入力 -->
      <div v-else class="login-container">
        <div class="left ml-5 mt-3 mb-5">
          パスワードを再設定します。<br>
          【 {{email}} 】<br>
          の新しいパスワードを入力して下さい
        </div>

        <p><v-text-field placeholder="パスワード" 
              v-model="password"
              type="password"
              :counter="20"
              :rules="password_rule"
              class="input-email"
        ></v-text-field></p>
        <p><v-text-field placeholder="パスワード 確認" 
              v-model="password2"
              type="password"
              :counter="20"
              :rules="[required, match(password)]"
              class="input-email"
        ></v-text-field></p>

        <p><input type="button" value="パスワードを設定" v-on:click='set_new_password()'></p>
      </div>

      <!-- <div class="message preline">
          {{ message }}
      </div> -->
    </div>

  </v-form>
</template>


<script>
import Axios from 'axios'
import Debug from '../Lib/Debug'

export default {
  name: 'Login',
  
  data: () => ({
    valid: true,

    mode: '',

    email: '',
    password: '', 
    password2: '', 

    remember_token: '',
  }),

  methods: {

    // パスワード再設定URL 発行
    async reset_password() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let params = new URLSearchParams();
      params.append('email', this.email);

      await Axios.post('/api/reset_password', params)
          .then(() => {
            alert("パスワード再設定の URL を送信しました");
          })
          .catch(err => {
            // this.message = "エラーが発生しました";
            alert("正しいメールアドレスを入力して下さい。");
            console.error(err);
          })
          .finally(() => {
            this.$router.push('/login')
          });
    },

    // remember_token から email を取得
    async getEmailAddress() {
      await Axios.get('/api/reset_password/' + this.remember_token)
          .then(res => {
            this.email = res.data.email;
            Debug.log(res);

            if (!this.email) {
              alert(res.data.message);
              
              // ログイン画面に戻す
              this.$router.push('/login')
            }
          })
          .catch ((err) => {
            console.error(err);
          });
    }, 

    // 新パスワード設定処理
    async set_new_password() {
      // バリデーション
      if (!this.$refs.form.validate()) {
        return;
      }

      let params = new URLSearchParams();
      params.append('email', this.email);
      params.append('password', this.password);

      await Axios.post('/api/reset_password/' + this.remember_token, params)
          .then(res => {
            Debug.log(res);

            if (res.status == 200) {
              alert("パスワードの変更が完了しました");
            }
          })
          .catch ((err) => {
            console.error(err);
            alert("パスワードの変更に失敗しました");
          })
          .finally(() => {
            // ログイン画面に戻す
            this.$router.push('/login')
          });
    },

    // バリデーションルール
    match: (v2) => (v) => {
      return v === v2 || 'パスワードが一致しません'
    },

  },

  // ライフサイクルフック
  created: function() {

  },

  mounted : function() {
    // Tokenを取得
    this.remember_token = this.$route.params['remember_token'];
    if (!this.remember_token) {
      this.mode = "email";

      Debug.log2("remember_token->", this.remember_token);
    } else {
      this.mode = "pass";

      this.getEmailAddress();
    }
  },

  computed: {

    // パスワード用バリデーション
    password_rule() {
      const msg = '20文字以下, 半角英数字/ﾊｲﾌﾝ/ｱﾝﾀﾞｰﾊﾞｰが使えます'
      const required = v => !!v || "必須項目です"
      const max_length= value => value.length <= 20 || "20文字以内で入力してください"
      const format_new = v => /^[\w-]{1,20}$/.test(v) || msg
      
      return [required, max_length, format_new];
    },

  }

}

</script>

<style scoped>
.login {
  width: 500px;
  margin: 30px auto;
  font-size: 20px;
}

/* Reset top and bottom margins from certain elements */
.login-header,
.login p {
  margin-top: 0;
  margin-bottom: 0;
}

.login-header {
  /* background: var(--v-primary-base); */
  background-color: #05a;
  padding: 20px;
  font-size: 1.4em;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.login-container {
  background: #ebebeb;
  padding: 12px;
}

/* Every row inside .login-container is defined with p tags */
.login p {
  padding: 12px;
}

.login input,
.input-email {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  outline: 0;
  font-family: inherit;
  font-size: 0.95em;
  background: #fff;
  border-color: #bbb;
  color: #555;
}

.input-email {
  padding-bottom: 4px;
}

/* Text fields' focus effect */
.login input[type="email"]:focus,
.login input[type="password"]:focus {
  border-color: #888;
}

.login input[type="button"] {
  font-size: 20px;
  color: #fff;
  /* background: var(--v-primary-base); */
  background-color: #05a;
}

.login input[type="submit"] {
  background: var(--v-primary-base);
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}

.login input[type="submit"]:hover {
  background: #17c;
}

/* Buttons' focus effect */
.login input[type="submit"]:focus {
  border-color: #05a;
}

.message {
  margin: 20px;
  text-align: center;
  font-weight: bold;
  color: red;
}

.checkbox {
  display: inline-block !important;
  width: auto !important; 
  margin-left: 50px;
  margin-right: 10px;
}

.checkbox-label {
  color: #000;
}

</style>